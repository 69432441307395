<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="showOperationHeader && isCreatingElement">
			<operation-form-header :modelPk="modelPk"></operation-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('form.resourceallocation.resourcedata')">
						<v-row dense class="mt-2">
							<v-col cols="2">
								<pui-select
									:id="`portid-${modelName}`"
									:attach="`portid-${modelName}`"
									:label="$t('form.resourceallocation.portname')"
									toplabel
									clearable
									:disabled="formDisabled || this.model.requestid != null || !isCreatingElement"
									v-model="model"
									modelName="port"
									:modelFormMapping="{ id: 'portid' }"
									:itemsToSelect="[{ id: this.model.portid }]"
									itemValue="id"
									itemText="portname"
									required
									:fixedFilter="filterByPortAuthorityAndPort"
									reactive
								></pui-select>
							</v-col>
							<v-col cols="2">
								<pui-select
									:id="`resourceallocationstatus-${modelName}`"
									attach="resourceallocationstatus"
									:label="$t('form.resourceallocation.resourceallocationstatus')"
									disabled
									v-model="model"
									modelName="resourceallocationstatus"
									:itemsToSelect="[{ code: model.resourceallocationstatus }]"
									:modelFormMapping="{ code: 'resourceallocationstatus' }"
									itemValue="code"
									itemText="name"
									toplabel
									reactive
								></pui-select>
							</v-col>
							<v-col cols="4">
								<pui-select
									:id="`resourcetypeid-${modelName}`"
									attach="resourcetypeid"
									:label="$t('form.resourceallocation.resourcetypeid')"
									required
									:disabled="formDisabled || this.model.requestid != null || !isCreatingElement"
									v-model="model"
									modelName="resourcetype"
									:itemsToSelect="[{ resourcetypeid: model.resourcetypeid }]"
									:modelFormMapping="{ resourcetypeid: 'resourcetypeid' }"
									itemValue="resourcetypeid"
									itemText="name"
									:fixedFilter="entityTypeFilter"
									toplabel
									reactive
								></pui-select>
							</v-col>
							<v-col cols="4">
								<pui-select
									:id="`resourceid-${modelName}`"
									attach="resourceid"
									:label="$t('form.resourceallocation.resourceid')"
									required
									:disabled="formDisabled || !enableResources || !isCreatingElement"
									v-model="model"
									modelName="resources"
									:itemsToSelect="[{ resourceid: model.resourceid }]"
									:modelFormMapping="{ resourceid: 'resourceid' }"
									itemValue="resourceid"
									itemText="description"
									:fixedFilter="resourceTypeFilter"
									toplabel
									reactive
								></pui-select>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="5" v-if="model.movementid">
								<pui-select
									:id="`movementid-${modelName}`"
									:attach="`movementid-${modelName}`"
									:label="$t('form.resourceallocation.movementnum')"
									toplabel
									clearable
									disabled
									v-model="model"
									modelName="movement"
									:itemsToSelect="[{ id: this.model.movementid }]"
									:modelFormMapping="{ id: 'movementid' }"
									itemValue="id"
									:itemText="
										(item) =>
											`${item.nummovement} - ${item.movementtype} - ${this.$dateTimeUtils.getLocalFormattedDate(
												item.starttime,
												'DD/MM/YYYY HH:mm'
											)} - ${this.$dateTimeUtils.getLocalFormattedDate(item.endtime, 'DD/MM/YYYY HH:mm')}
											- ${item.movementstatus}`
									"
									:order="{ nummovement: 'asc' }"
									detailComponentName="movement-form"
									detailModelName="movement"
								></pui-select>
							</v-col>
							<v-col cols="5" v-if="model.stopid">
								<pui-select
									:id="`stopid-${modelName}`"
									:attach="`stopid-${modelName}`"
									:label="$t('form.resourceallocation.stopnum')"
									toplabel
									clearable
									disabled
									v-model="model"
									modelName="stop"
									:itemsToSelect="[{ id: this.model.stopid }]"
									:modelFormMapping="{ id: 'stopid' }"
									itemValue="id"
									:itemText="(item) => getStopItemText(item)"
									:order="{ num: 'asc' }"
									reactive
									detailComponentName="stop-form"
									detailModelName="stop"
								></pui-select>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="5">
								<pui-select
									:id="`operationid-${modelName}`"
									:attach="`operationid-${modelName}`"
									v-model="model"
									:label="$t('form.resourceallocation.operation')"
									:disabled="
										model.resourceallocationstatus == 'CA' ||
										model.resourceallocationstatus == 'FI' ||
										formDisabled ||
										disabledSoli
									"
									toplabel
									clearable
									modelName="operation"
									:itemsToSelect="itemsToSelectOperation"
									:modelFormMapping="{ operationid: 'operationid' }"
									itemValue="operationid"
									:itemText="(item) => getOperationItemText(item)"
									reactive
									detailComponentName="operationform"
									detailModelName="operation"
									:fixedFilter="filterByStop"
								></pui-select>
							</v-col>
							<v-col cols="5" v-if="model.serviceid">
								<pui-select
									:id="`serviceid-${modelName}`"
									:attach="`serviceid-${modelName}`"
									v-model="model"
									:label="$t('form.resourceallocation.serviceid')"
									disabled
									toplabel
									clearable
									modelName="technicalnauticalservice"
									:itemsToSelect="[{ serviceid: this.model.serviceid }]"
									:modelFormMapping="{ serviceid: 'serviceid' }"
									itemValue="serviceid"
									:itemText="
										(item) =>
											`${item.servicetypename} - ${
												item.startdatereq
													? this.$dateTimeUtils.getLocalFormattedDate(item.startdatereq, 'DD/MM/YYYY HH:mm')
													: ''
											} - ${
												item.enddatereq ? this.$dateTimeUtils.getLocalFormattedDate(item.enddatereq, 'DD/MM/YYYY HH:mm') : ''
											}`
									"
									reactive
									:detailComponentName="serviceComponentName"
									:detailModelName="serviceModelName"
								></pui-select>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set :title="$t('form.fieldset.plandata')">
						<v-row dense>
							<v-col cols="5">
								<pui-date-field
									:id="`startdate-${modelName}`"
									v-model="model.startdate"
									:label="$t('form.resourceallocation.startdate')"
									:disabled="formDisabled || disabledSoli"
									required
									toplabel
									clearable
									time
									:max="model.enddate ? model.enddate : null"
								></pui-date-field>
							</v-col>
							<v-col cols="5">
								<pui-date-field
									:id="`enddate-${modelName}`"
									v-model="model.enddate"
									:label="$t('form.resourceallocation.enddate')"
									:disabled="formDisabled || disabledSoli"
									required
									toplabel
									clearable
									time
									:min="model.startdate ? model.startdate : null"
								></pui-date-field>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set :title="$t('form.fieldset.authdata')" v-if="!isCreatingElement && model.resourceallocationstatus != 'PL'">
						<v-row dense>
							<v-col cols="5">
								<pui-date-field
									:id="`authstartdate-${modelName}`"
									v-model="model.authstartdate"
									:label="$t('form.resourceallocation.authstartdate')"
									:disabled="formDisabled || disabledAuth"
									toplabel
									clearable
									time
									:max="model.authenddate ? model.authenddate : null"
								></pui-date-field>
							</v-col>
							<v-col cols="5">
								<pui-date-field
									:id="`authenddate-${modelName}`"
									v-model="model.authenddate"
									:label="$t('form.resourceallocation.authenddate')"
									:disabled="formDisabled || disabledAuth"
									toplabel
									clearable
									time
									:min="model.authstartdate ? model.authstartdate : null"
								></pui-date-field>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set
						:title="$t('form.fieldset.actualdata')"
						v-if="!isCreatingElement && model.resourceallocationstatus != 'PL' && model.resourceallocationstatus != 'AU'"
					>
						<v-row dense>
							<v-col cols="5" v-if="!isCreatingElement">
								<pui-date-field
									:id="`actualstartdate-${modelName}`"
									v-model="model.actualstartdate"
									:label="$t('form.resourceallocation.actualstartdate')"
									:disabled="formDisabled || disabledReal"
									toplabel
									clearable
									time
									:max="model.actualenddate ? model.actualenddate : null"
								></pui-date-field>
							</v-col>
							<v-col cols="5" v-if="!isCreatingElement">
								<pui-date-field
									:id="`actualenddate-${modelName}`"
									v-model="model.actualenddate"
									:label="$t('form.resourceallocation.actualenddate')"
									:disabled="formDisabled || disabledRealFin"
									toplabel
									clearable
									time
									:min="model.actualstartdate ? model.actualstartdate : null"
								></pui-date-field>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set :title="$t('form.fieldset.usedata')" v-if="!isCreatingElement">
						<v-row dense>
							<v-col cols="4" v-if="!isCreatingElement">
								<pui-number-field
									:id="`tons-${modelName}`"
									v-model="model.tons"
									:label="$t('form.resourceallocation.tons')"
									:disabled="formDisabled || disabledRealFin"
									toplabel
								></pui-number-field>
							</v-col>
							<v-col cols="2" v-if="!isCreatingElement">
								<pui-checkbox
									:id="`parttimejob-${modelName}`"
									:label="$t('form.resourceallocation.parttimejob')"
									v-model="model.parttimejob"
									:disabled="formDisabled || (disabledSoli && disabledAuth && disabledReal)"
								></pui-checkbox>
								<pui-checkbox
									:id="`rented-${modelName}`"
									:label="$t('form.resourceallocation.rented')"
									v-model="model.rented"
									:disabled="formDisabled || (disabledSoli && disabledAuth && disabledReal)"
								></pui-checkbox>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="4" v-if="!isCreatingElement">
								<pui-number-field
									:id="`delay-${modelName}`"
									v-model="delay"
									:label="$t('form.resourceallocation.delay')"
									disabled
									toplabel
								></pui-number-field>
							</v-col>
							<v-col cols="4" v-if="!isCreatingElement">
								<pui-select
									:id="`delayreason-${modelName}`"
									:attach="`delayreason-${modelName}`"
									v-model="model"
									:label="$t('form.resourceallocation.delayreason')"
									toplabel
									:required="delay > 0"
									clearable
									:disabled="formDisabled || (disabledSoli && disabledAuth && disabledReal)"
									modelName="delayreason"
									:itemsToSelect="[{ id: model.delayreason }]"
									:modelFormMapping="{ id: 'delayreason' }"
									itemValue="id"
									itemText="description"
									reactive
								></pui-select>
							</v-col>
							<v-col cols="4" v-if="!isCreatingElement && model.resourceallocationstatus == 'CA'">
								<pui-select
									:id="`cancelationreason-${modelName}`"
									:attach="`cancelationreason-${modelName}`"
									v-model="model"
									:label="$t('form.resourceallocation.cancelationreason')"
									toplabel
									clearable
									:disabled="formDisabled || (disabledSoli && disabledAuth && disabledReal)"
									modelName="cancelationreason"
									:itemsToSelect="[{ id: model.cancelationreason }]"
									:modelFormMapping="{ id: 'cancelationreason' }"
									itemValue="id"
									itemText="description"
									reactive
								></pui-select>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="4" v-if="!isCreatingElement">
								<pui-number-field
									:id="`timenotused-${modelName}`"
									v-model="model.timenotused"
									:label="$t('form.resourceallocation.timenotused')"
									:disabled="formDisabled || (disabledSoli && disabledAuth && disabledReal)"
									toplabel
								></pui-number-field>
							</v-col>
							<v-col cols="4" v-if="!isCreatingElement">
								<pui-select
									:id="`reasonnotused-${modelName}`"
									:attach="`reasonnotused-${modelName}`"
									v-model="model"
									:label="$t('form.resourceallocation.reasonnotused')"
									toplabel
									:required="model.timenotused > 0"
									clearable
									:disabled="formDisabled || (disabledSoli && disabledAuth && disabledReal)"
									modelName="notusedreason"
									:itemsToSelect="[{ id: model.reasonnotused }]"
									:modelFormMapping="{ id: 'reasonnotused' }"
									itemValue="id"
									itemText="description"
									reactive
								></pui-select>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="3" v-if="!isCreatingElement">
								<pui-number-field
									:id="`realtime-${modelName}`"
									v-model="realtime"
									:label="$t('form.resourceallocation.realtime')"
									disabled
									toplabel
								></pui-number-field>
							</v-col>
							<v-col cols="3" v-if="!isCreatingElement">
								<pui-number-field
									:id="`realtimehours-${modelName}`"
									v-model="realtimehours"
									:label="$t('form.resourceallocation.realtimehours')"
									disabled
									toplabel
								></pui-number-field>
							</v-col>
							<v-col cols="4" v-if="!isCreatingElement">
								<pui-select
									:id="`resourceoperationtype-${modelName}`"
									:attach="`resourceoperationtype-${modelName}`"
									v-model="model"
									:label="$t('form.resourceallocation.resourceoperationtype')"
									toplabel
									clearable
									:disabled="formDisabled || (disabledSoli && disabledAuth && disabledReal)"
									modelName="resourceoperationtype"
									:itemsToSelect="[{ id: model.resourceoperationtype }]"
									:modelFormMapping="{ id: 'resourceoperationtype' }"
									itemValue="id"
									itemText="description"
									reactive
								></pui-select>
							</v-col>
							<v-col cols="10" v-if="!isCreatingElement">
								<pui-text-area
									:id="`comments-${modelName}`"
									v-model="model.comments"
									:label="$t('form.resourceallocation.comments')"
									:disabled="formDisabled"
									toplabel
									maxlength="300"
								></pui-text-area>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import OperationFormHeader from '../operation/OperationFormHeader.vue';

export default {
	name: 'resourceallocation-form',
	mixins: [PuiFormMethodsMixin],
	components: { OperationFormHeader },
	data() {
		return {
			modelName: 'resourceallocation',
			enableResources: false,
			resourceTypeFilter: null,
			parentModelName: null,
			entityType: null,
			serviceTypeId: null,
			serviceComponentName: null,
			serviceModelName: null,
			parentModelIdName: 'id',
			showOperationHeader: false,
			delay: 0,
			realtime: 0,
			realtimehours: 0,
			visitid: null,
			disabledSoli: true,
			disabledAuth: true,
			disabledReal: true,
			disabledRealFin: true
		};
	},
	methods: {
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;

			return `${item.num} - ${this.$dateTimeUtils.getLocalFormattedDate(
				startdate,
				'DD/MM/YYYY HH:mm'
			)} - ${this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm')} - ${item.status} - ${item.berthnowname}`;
		},
		getOperationItemText(item) {
			let stevedore = item.stevedorecompanyname ? item.stevedorecompanyname : null;
			let textWithoutStevedore = `${item.operationtype} - ${
				item.startdate ? this.$dateTimeUtils.getLocalFormattedDate(item.startdate, 'DD/MM/YYYY HH:mm') : ''
			} - ${item.enddate ? this.$dateTimeUtils.getLocalFormattedDate(item.enddate, 'DD/MM/YYYY HH:mm') : ''}`;
			let loadtype = item.loadtypename ? item.loadtypename : '';
			let finalText = stevedore
				? textWithoutStevedore.concat(`- ${item.stevedorecompanyname}`).concat(' - ' + loadtype)
				: textWithoutStevedore.concat(' - ' + loadtype);

			return finalText;
		},
		getServiceTypeId() {
			if (this.model.serviceid != null) {
				const opts = {
					model: 'technicalnauticalservice',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'serviceid',
								op: 'eq',
								data: this.model.serviceid
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					if (response.data.data[0]) {
						this.serviceTypeId = response.data.data[0].servicetypeid;

						if (!this.model.portid && response.data.data[0].portid) {
							this.model.portid = response.data.data[0].portid;
						}
					}
				});
			}
		},
		getServiceComponentName() {
			if (this.serviceTypeId != null) {
				if (this.serviceTypeId == 1) {
					this.serviceComponentName = 'pilotageform';
					this.serviceModelName = 'pilotage';
				} else if (this.serviceTypeId == 2) {
					this.serviceComponentName = 'towage-form';
					this.serviceModelName = 'towage';
				} else {
					this.serviceComponentName = 'mooring-form';
					this.serviceModelName = 'mooring';
				}
			}
		},
		afterGetData() {
			this.setParentPk();
			this.setEntityType();
			if (this.model.serviceid) {
				this.getServiceTypeId();
			}
			this.calculateStats();
			this.disabledSoli = false;

			if (this.model.resourceallocationstatus === 'CA' || this.model.resourceallocationstatus === 'VA') {
				this.disabledSoli = true;
				this.formDisabled = true;
			}
			if (this.model.resourceallocationstatus === 'AU') {
				this.formDisabled = false;
				this.disabledSoli = true;
				if (this.$store.getters.getSession.functionalities.includes('ACTION_AUTHORIZE_RESOURCE_ALLOCATION')) {
					this.disabledAuth = false;
				}
			}
			if (this.model.resourceallocationstatus === 'PR') {
				this.formDisabled = false;
				this.disabledSoli = true;
				this.disabledAuth = true;
				if (this.$store.getters.getSession.functionalities.includes('ACTION_INITIATE_RESOURCE_ALLOCATION')) {
					this.disabledReal = false;
				}
			}
			if (this.model.resourceallocationstatus === 'FI') {
				if (this.$store.getters.getSession.functionalities.includes('ACTION_END_RESOURCE_ALLOCATION')) {
					this.disabledRealFin = false;
				}
				this.formDisabled = false;
				this.disabledSoli = true;
				this.disabledAuth = true;
				this.disabledReal = true;
			}
		},
		calculateStats() {
			if (this.model.actualstartdate && this.model.authstartdate) {
				let startdate = new Date(this.model.actualstartdate);
				let enddate = new Date(this.model.authstartdate);
				var diff = startdate - enddate;
				this.delay = diff / (1000 * 60);
				if (this.delay < 0) {
					this.delay = 0;
				}
			}
			if (this.model.actualstartdate && this.model.actualenddate) {
				let startdate = new Date(this.model.actualstartdate);
				let enddate = new Date(this.model.actualenddate);
				var diff = enddate - startdate;
				console.log(diff);
				this.realtime = diff / (1000 * 60) - this.model.timenotused;
				this.realtimehours = (this.realtime / 60).toFixed(2);
			}
		},
		setParentPk() {
			const currentPath = this.$router.currentRoute.path.split('/');
			this.parentModelName = currentPath[1];
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);

			if (parentModel) {
				if (parentModel.pk.operationid) {
					this.model.operationid = parentModel.pk.operationid;
					this.entityType = 'O';
					this.showOperationHeader = true;

					if (!this.model.portid) {
						this.parentModelIdName = 'operationid';
						this.getDataFromParentModel(parentModel.modelName, this.parentModelIdName, this.model.operationid);
					}
				} else if (parentModel.pk.id && this.parentModelName.includes('movement')) {
					this.model.movementid = parentModel.pk.id;
					this.entityType = 'M';

					if (!this.model.portid) {
						this.getDataFromParentModel(parentModel.modelName, this.parentModelIdName, this.model.movementid);
					}
				} else if (parentModel.pk.id && this.parentModelName === 'stop') {
					this.model.stopid = parentModel.pk.id;
					this.entityType = 'A';

					if (!this.model.portid) {
						this.getDataFromParentModel(parentModel.modelName, this.parentModelIdName, this.model.stopid);
					}
				} else if (parentModel.pk.serviceid) {
					this.model.serviceid = parentModel.pk.serviceid;
					this.entityType = 'S';

					if (!this.model.portid) {
						this.parentModelIdName = 'serviceid';
						this.getDataFromParentModel(parentModel.modelName, this.parentModelIdName, this.model.serviceid);
					}
				} else if (parentModel.pk.id && this.parentModelName === 'resourcerequest') {
					this.model.requestid = parentModel.pk.id;

					if (!this.model.portid) {
						this.getDataFromParentModel(parentModel.modelName, this.parentModelIdName, this.model.requestid);
					}
				}
			}
		},
		setEntityType() {
			this.entityTypeFilter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'associatedentities', op: 'cn', data: this.entityType }]
			};
		},
		getDataFromParentModel(modelName, parentModelIdName, parentModelId) {
			const opts = {
				model: modelName,
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: parentModelIdName,
							op: 'eq',
							data: parentModelId
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.model.portid = response.data.data[0].portid;

					this.model.startdate = response.data.data[0].startdate
						? response.data.data[0].startdate
						: response.data.data[0].startdateplanned
						? response.data.data[0].startdateplanned
						: response.data.data[0].startdatereq;

					this.model.enddate = response.data.data[0].enddate
						? response.data.data[0].enddate
						: response.data.data[0].enddateplanned
						? response.data.data[0].enddateplanned
						: response.data.data[0].enddatereq;

					if (response.data.data[0].locationid) {
						this.model.stopid = response.data.data[0].stopid ? response.data.data[0].stopid : response.data.data[0].id;
						this.model.berthid = response.data.data[0].locationid;
					} else if (response.data.data[0].berthto) {
						this.model.stopid = response.data.data[0].stopto;
						this.model.berthid = response.data.data[0].berthto;
					}
					if (modelName === 'resourcerequest') {
						console.log(response.data.data[0].operationid);
						this.model.operationid = response.data.data[0].operationid;
						this.model.resourcetypeid = response.data.data[0].resourcetype;
						this.visitid = response.data.data[0].visitid;
					}
				}
			});
		},
		filterResources() {
			if (this.model.resourcetypeid) {
				if (this.model.berthid) {
					this.resourceTypeFilter = {
						groupOp: 'and',
						groups: [
							{
								groupOp: 'or',
								rules: [
									{ field: 'disableddate', op: 'nu', data: null },
									{ field: 'disableddate', op: 'gt', data: this.model.startdate }
								]
							},
							{
								rules: [
									{ field: 'resourcetypeid', op: 'eq', data: this.model.resourcetypeid },
									{ field: 'berthid', op: 'eq', data: this.model.berthid }
								]
							}
						]
					};
				} else {
					this.resourceTypeFilter = {
						groupOp: 'and',
						groups: [
							{
								groupOp: 'or',
								rules: [
									{ field: 'disableddate', op: 'nu', data: null },
									{ field: 'disableddate', op: 'gt', data: this.model.startdate }
								]
							},
							{
								rules: [{ field: 'resourcetypeid', op: 'eq', data: this.model.resourcetypeid }]
							}
						]
					};
				}
			}
		}
	},
	computed: {
		itemsToSelectOperation() {
			return [{ operationid: this.model.operationid }];
		},
		filterByPortAuthorityAndPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') },
					{ field: 'id', op: 'in', data: this.$store.getters.getPortsIds }
				]
			};
		},
		filterByStop() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [, { field: 'visitid', op: 'eq', data: this.visitid }]
			};
		}
	},
	watch: {
		'model.resourcetypeid'(newVal) {
			if (newVal !== null) {
				this.filterResources();
				this.enableResources = true;
			} else {
				this.enableResources = false;
			}
		},
		'model.startdate'(newVal) {
			if (newVal !== null) {
				this.filterResources();
			}
		},
		serviceTypeId(newVal) {
			if (newVal !== null) {
				this.getServiceComponentName();
			}
		},
		'model.berthid'(newVal) {
			if (newVal !== null && this.model.resourcetypeid) {
				this.resourceTypeFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'resource_type_id', op: 'eq', data: this.model.resourcetypeid },
						{ field: 'berth_id', op: 'eq', data: this.model.berthid }
					]
				};
				this.enableResources = true;
			}
		},
		'model.authstartdate'(newVal) {
			if (newVal !== null) {
				this.calculateStats();
			}
		},
		'model.actualstartdate'(newVal) {
			if (newVal !== null) {
				this.calculateStats();
			}
		},
		'model.actualenddate'(newVal) {
			if (newVal !== null) {
				this.calculateStats();
			}
		}
	},
	created() {}
};
</script>
